import phone from "../assets/images/phone.png";
import { Button } from "reactstrap";
import { FaApple } from "react-icons/fa";
import { FaGooglePlay } from "react-icons/fa6";

const Advertisement = () => {
  return (
    <div className="blue-container">
      <div className="upper-elipse" />
      <div className="content">
        <div className="start">
          <h1 className="title">Get Started</h1>
          <span style={{ fontSize: "20px" }}>3 easy steps until you're ready to start working</span>
          <div
            className="d-flex flex-column gap-3"
            style={{ paddingTop: "40px" }}
          >
            <div className="card">
              <div className="d-flex gap-2 align-items-center">
                <div className="number">1</div>
                <h2>Download the app</h2>
              </div>

              <p>
                Download our app for quick access to functions from your
                smartphone!
              </p>
              <div className="d-flex ad-buttons gap-3 ">
                <Button
                  href="https://apps.apple.com/us/app/mastered-it/id6483932643"
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    borderRadius: "15px",
                    minWidth: "172px",
                    minHeight: "55px",
                    background: "white",
                    color: "#0039c7",
                  }}
                >
                  <div className="d-flex gap-2 align-items-center justify-content-center fs-16 fw-bold">
                    <FaApple style={{ width: "24px", height: "30px" }} />
                    App Store
                  </div>
                </Button>
                <Button
                  href="https://play.google.com/store/apps/details?id=com.mastered"
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    borderRadius: "15px",
                    minWidth: "172px",
                    minHeight: "55px",
                    background: "white",
                    color: "#0039c7",
                  }}
                >
                  <div className="d-flex gap-2 align-items-center justify-content-center fs-16 fw-bold">
                    <FaGooglePlay style={{ width: "24px", height: "30px" }} />
                    Google Play
                  </div>
                </Button>
              </div>
            </div>
            <div className="card">
              <div className="d-flex gap-2 align-items-center">
                <div className="number">2</div>
                <h2>Register your account</h2>
              </div>

              <p>
                Use the phone number or email you provided to your manager to
                gain access to the Mastered App
              </p>
            </div>
            <div className="card">
              <div className="d-flex gap-2 align-items-center">
                <div className="number">3</div>
                <h2>Complete your onboarding courses</h2>
              </div>

              <p>Courses can be completed right in the app</p>
            </div>
          </div>
        </div>
        <img className="phone" src={phone} alt={"phone"} />
      </div>
      <div className="blue-box" />
      <div className="lower-elipse" />
    </div>
  );
};

export default Advertisement;
