import { TFacilitySettings } from "../../models/facility";
import { API } from "../api_helper";

export const COMPANIES = "/public-companies";

export type TCredentials = {
  createdAt: Date;
  credential: string;
  credentialType: string;
  id: number;
  isDeleted: boolean;
  isVerified: boolean;
  lastUsageDate: Date;
  updatedAt: Date;
  userId: number;
};

export const getCompanyByName = (company: string, facility: string) => {
  return API.get<{
    HRManager: { email?: TCredentials; phone?: TCredentials; name?: string };
    facilityPhone: string;
    settings: TFacilitySettings;
  }>(`${COMPANIES}/name/${company}/${facility}`);
};
