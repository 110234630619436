import React, { useEffect, useState } from "react";
import "../assets/scss/facility-landing.scss";
import { useNavigate, useParams } from "react-router-dom";
import { TFacilitySettings } from "../models/facility";
import { Button } from "reactstrap";
import firstbgImage from "../assets/images/bg-gradient-img.png";
import secondbgImage from "../assets/images/bg-ellipse.png";
import logo from "../assets/images/logo.png";
import { TCredentials, getCompanyByName } from "../helpers/api/facility";
import { Helmet } from "react-helmet-async";
import Advertisement from "../components/Advertisement";
import HelpBlock from "../components/HelpBlock";
import Footer from "../components/Footer";
import { FaApple, FaGooglePlay } from "react-icons/fa";
import VideoPlayer from "../components/VideoPlayer";

function toTitle(str: string): string {
  return str
    .replace(/_/g, " ")
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

const FacilityLandingPage = () => {
  const navigate = useNavigate();
  const { company, facility } = useParams<{
    company: string;
    facility: string;
  }>();
  const [settings, setSettings] = useState<TFacilitySettings>();
  const [title, setTitle] = useState<string>("Welcome to ");
  const [facilityPhone, setFacilityPhone] = useState<string>("");
  const [user, setUser] = useState<{
    email?: TCredentials;
    phone?: TCredentials;
    name?: string;
  }>();

  useEffect(() => {
    if (facility) {
      setTitle(`Welcome to ${toTitle(facility)}!`);
    }
  }, [facility]);

  useEffect(() => {
    if (company && facility) {
      getCompanyByName(company, facility)
        .then((res) => {
          setSettings(res.data.settings);
          setFacilityPhone(res.data.facilityPhone);
          setUser(res.data.HRManager);
        })
        .catch(() => navigate("/"));
    }
  }, [company, facility, navigate]);

  const videos = [settings?.defaultVideo?.path, settings?.video?.path].filter(
    Boolean
  );

  return (
    <div className="facility-landing-body">
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta name="title" content={title} />
      </Helmet>
      <div className="facility-landing-content">
        <img className="first-bg-image" src={firstbgImage} alt={""} />
        <img className="second-bg-image" src={secondbgImage} alt={""} />
        <div className="d-flex justify-content-between align-items-center header">
          <img className="logo" src={logo} alt={""} />
          <div className="d-flex gap-1">
            <Button
              href="https://apps.apple.com/us/app/mastered-it/id6483932643"
              className="d-flex align-items-center justify-content-center header-btn"
              style={{
                borderRadius: "15px",
                minWidth: "172px",
                minHeight: "55px",
                background: "#0039c7",
              }}
            >
              <div className="d-flex gap-2 align-items-center justify-content-center fs-16 fw-bold">
                <FaApple style={{ width: "24px", height: "30px" }} />
                App Store
              </div>
            </Button>
            <Button
              href="https://play.google.com/store/apps/details?id=com.mastered"
              className="d-flex align-items-center justify-content-center header-btn"
              style={{
                borderRadius: "15px",
                minWidth: "172px",
                minHeight: "55px",
                background: "#0039c7",
              }}
            >
              <div className="d-flex gap-2 align-items-center justify-content-center fs-16 fw-bold">
                <FaGooglePlay style={{ width: "24px", height: "30px" }} />
                Google Play
              </div>
            </Button>
          </div>
        </div>
        <div className="w-100 d-flex justify-content-center">
          <div className="title d-flex flex-column align-items-center justify-content-center">
            <span
              className="text-muted fs-18 fw-bold"
              style={{ paddingBottom: "12px" }}
            >
              {settings?.logo?.path && (
                <img
                  style={{
                    marginRight: "8.5px",
                  }}
                  src={settings?.logo?.path}
                  alt="Logo"
                />
              )}
              {facility && !settings?.logo?.path ? (
                <span style={{ fontSize: "30px" }}>{toTitle(facility)}</span>
              ) : (
                ""
              )}
            </span>
            <h1 className="fw-bold text-black">Welcome to the team!</h1>
            <span className="text-muted fw-medium" style={{ fontSize: "20px" }}>
              Complete your onboarding in 3 simple steps.
            </span>
            <div className="d-flex buttons gap-1">
              <Button
                href="https://apps.apple.com/us/app/mastered-it/id6483932643"
                className="d-flex align-items-center justify-content-center"
                style={{
                  borderRadius: "15px",
                  minWidth: "172px",
                  minHeight: "55px",
                  background: "#0039c7",
                }}
              >
                <div className="d-flex gap-2 align-items-center justify-content-center fs-16 fw-bold">
                  <FaApple style={{ width: "24px", height: "30px" }} />
                  App Store
                </div>
              </Button>
              <Button
                as="a"
                href="https://play.google.com/store/apps/details?id=com.mastered"
                className="d-flex align-items-center justify-content-center"
                style={{
                  borderRadius: "15px",
                  minWidth: "172px",
                  minHeight: "55px",
                  background: "#0039c7",
                }}
              >
                <div className="d-flex gap-2 align-items-center justify-content-center fs-16 fw-bold">
                  <FaGooglePlay style={{ width: "24px", height: "30px" }} />
                  Google Play
                </div>
              </Button>
            </div>
          </div>
        </div>
        <div className="w-100 d-flex justify-content-center">
          <div className={`video${videos.length > 1 ? "-multiple" : ""}`}>
            {settings?.defaultVideo?.path && (
              <VideoPlayer videoPath={settings?.defaultVideo?.path} />
            )}
            {settings?.video?.path && (
              <VideoPlayer videoPath={settings?.video?.path} />
            )}
          </div>
        </div>

        {(settings?.welcomeMessage || settings?.defaultWelcomeMessage) && (
          <div className="w-100 d-flex justify-content-center">
            <span className="welcome-text fw-medium text-muted">
              {settings?.welcomeMessage || settings?.defaultWelcomeMessage}
            </span>
          </div>
        )}
        <Advertisement />
        <HelpBlock user={user} facilityPhone={facilityPhone} />
        <Footer />
      </div>
    </div>
  );
};

export default FacilityLandingPage;
