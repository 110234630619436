import React from "react";
import { FaPhoneVolume } from "react-icons/fa6";
import { TCredentials } from "../helpers/api/facility";

const HelpBlock = ({
  user,
  facilityPhone,
}: {
  user?: { email?: TCredentials; phone?: TCredentials; name?: string };
  facilityPhone?: string;
}) => {
  return (
    <div className="d-flex w-100 justify-content-center">
      <div className="help-block">
        <div className="content">
          <h1>Need help? Be in touch!</h1>
          {user?.name ? (
            <React.Fragment>
              <h2>{user.name}</h2>
              <span>Human Resources Manager</span>
            </React.Fragment>
          ) : (
            <span>Human Resources Department</span>
          )}
          <div className="d-flex w-100 justify-content-center">
            <div className="d-flex flex-row justify-content-center gap-2 credential-block">
              <a
                href={`tel:${facilityPhone}`}
                style={{ textDecoration: "none" }}
              >
                <div className="credentials">
                  <FaPhoneVolume />
                  {facilityPhone}
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpBlock;
