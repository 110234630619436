import firstbgImage from "../assets/images/bg-gradient-img.png";
import secondbgImage from "../assets/images/bg-ellipse.png";
import logo from "../assets/images/logo.png";
import notFoundImage from "../assets/images/img-not-found.png";
import Footer from "../components/Footer";

const NotFoundPage = () => {
  return (
    <div className="facility-landing-body">
      <div className="facility-landing-content">
        <img className="not-found-first-bg-image" src={firstbgImage} alt={""} />
        <img
          className="not-found-second-bg-image"
          style={{ top: "-80px" }}
          src={secondbgImage}
          alt={""}
        />
        <div className="d-flex justify-content-center align-items-center header">
          <img className="logo" src={logo} alt={""} />
        </div>
        <div
          className="w-100 d-flex justify-content-center"
          style={{ height: "390px", margin: "152px 0" }}
        >
          <div className="d-flex flex-column align-items-center">
            <img className="not-found-image" src={notFoundImage} alt=""></img>
            <span className="not-found-title">Page Not Found</span>
            <span className="not-found-sub-title">
              Contact your manager to get the correct link
            </span>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default NotFoundPage;
