import React, { useRef, useState } from "react";
import { Button } from "reactstrap";
import { FaPlay } from "react-icons/fa";

const VideoPlayer = ({ videoPath }: { videoPath: string }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };

  return (
    <div className="video-container" style={{ position: "relative" }}>
      <video
        controls
        src={videoPath}
        ref={videoRef}
        onPlay={handlePlay}
        onPause={handlePause}
      />
      <Button
        className={`play-button ${isPlaying ? "hidden" : ""}`}
        style={{
          paddingLeft: "18px",
        }}
        onClick={() => {
          if (videoRef.current) {
            videoRef.current.play();
          }
        }}
      >
        <FaPlay />
      </Button>
    </div>
  );
};

export default VideoPlayer;
